import {
  defineAsyncComponent
} from "vue";
import {
  init
} from "./vue/app";
import {
  initIntersections
} from "../../global/features/lazy-load";

init({
  CategoryPager: defineAsyncComponent(() =>
    import("./vue/components/CategoryPager.vue")
  ),
});

initIntersections();